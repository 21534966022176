// 公用js，建议使用IIFE（立即执行函数）
;(function() {
  $(function(){
    // 这里写代码
    var datenum = new Date();
    $("body").find('.this-year').text(datenum.getFullYear());
    chatonlineHtml = "<div class='chatonline'><div class='inner-chatonline'><div class='btn-switch-online'>在线交流</div><div class='chatonline-box'><div class='chatonline-title'>在线交流</div><div class='item-title'>招生咨询一</div><a href='http://wpa.qq.com/msgrd?v=3&amp;uin=2561764424&amp;site=qq&amp;menu=yes' target='_blank'  class='item-link'>QQ交流</a><div class='item-title'>招生咨询二</div><a href='http://wpa.qq.com/msgrd?v=3&uin=275629363&site=qq&menu=yes' target='_blank'  class='item-link'>QQ交流</a><div class='item-title'>同和校区：</div><div class='item-title'>020-37371053</div><div class='item-title'>江高校区：</div><div class='item-title'>020-86208779</div><a class='zxbm-link' href='/zsdt/register.html?class_id=1'>在线报名</a></div></div></div>";
    $("body").append(chatonlineHtml);
  })
})()
